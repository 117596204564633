import AWS from "aws-sdk";
import {accessKeyId, secretAccessKey} from "../constants/config";
import moment from 'moment';

export const formatDateMoment = (date) => {
  return moment(date).format("DD/MM/YYYY")
}

export const addCommas = nStr => {
  nStr += ''
  var x = nStr.split('.')
  var x1 = x[0]
  var x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key]
    var B = b[key]
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1
    } else {
      return -1
    }
  })
  return array
}

export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 // January is 0!

  var yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return dd + '.' + mm + '.' + yyyy
}

export const getCurrentTime = () => {
  const now = new Date()
  return now.getHours() + ':' + now.getMinutes()
}

export const formatDateWithDayHourSeconds = (date) => {
  // EX: 99/99/9999 99:99:99
  return new Date(date).toLocaleString('pt-BR', {timeZone: 'UTC'}).replaceAll(",", "")
}

export const formatCel = (cel) => {
  // EX: 859XXXXXXXX -> (85)9 XXXX-XXXX
  if (cel.length == 11) {
    return cel.replace(/(\d{0})(\d{2})(\d{1})(\d{4})(\d{4})/, '$1($2)$3 $4-$5');
  } else if (cel.indexOf('55') !== -1) {
    if (cel.length == 12) {
      // EX: 5585XXXXXXXX -> +55 85 XXXX-XXXX
      return cel.replace(/(d{0})(\d{2})(\d{2})(\d{4})(\d{4})/, '$1+$2 $3 $4-$5');
    } else {
      // EX: 55859XXXXXXXX -> +55 85 9 XXXX-XXXX
      return cel.replace(/(d{0})(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, '$1+$2 $3 $4 $5-$6');
    }
  }
}

export const getImgBannerFromName = (bandeira) => {
  try {
    return require(`@/assets/img/${bandeira.toLowerCase()}.svg`)
  } catch(e) {
    console.log(e)
    return (`@/assets/img/desconhecida.svg`)
  }
}

export const ThemeColors = () => {
  let rootStyle = getComputedStyle(document.body)
  return {
    themeColor1: rootStyle.getPropertyValue('--theme-color-1').trim(),
    themeColor2: rootStyle.getPropertyValue('--theme-color-2').trim(),
    themeColor3: rootStyle.getPropertyValue('--theme-color-3').trim(),
    themeColor4: rootStyle.getPropertyValue('--theme-color-4').trim(),
    themeColor5: rootStyle.getPropertyValue('--theme-color-5').trim(),
    themeColor6: rootStyle.getPropertyValue('--theme-color-6').trim(),
    themeColor1_10: rootStyle.getPropertyValue('--theme-color-1-10').trim(),
    themeColor2_10: rootStyle.getPropertyValue('--theme-color-2-10').trim(),
    themeColor3_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    themeColor4_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    themeColor5_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    themeColor6_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    primaryColor: rootStyle.getPropertyValue('--primary-color').trim(),
    foregroundColor: rootStyle.getPropertyValue('--foreground-color').trim(),
    separatorColor: rootStyle.getPropertyValue('--separator-color').trim(),
    colorELO: rootStyle.getPropertyValue('--theme-collor-elo-1').trim(),
    colorELO_10: rootStyle.getPropertyValue('--theme-collor-elo-1-10').trim(),
    colorMASTERCARD: rootStyle.getPropertyValue('--theme-collor-master-1').trim(),
    colorMASTERCARD_10: rootStyle.getPropertyValue('--theme-collor-master-1-10').trim(),
    colorVISA: rootStyle.getPropertyValue('--theme-collor-visa-1').trim(),
    colorVISA_10: rootStyle.getPropertyValue('--theme-collor-visa-1-10').trim(),
    colorHIPERCARD: rootStyle.getPropertyValue('--theme-collor-hiper-1').trim(),
    colorHIPERCARD_10: rootStyle.getPropertyValue('--theme-collor-hiper-1-10').trim()
  }
}

export const chartTooltip = {
  backgroundColor: ThemeColors().foregroundColor,
  titleFontColor: ThemeColors().primaryColor,
  borderColor: ThemeColors().separatorColor,
  borderWidth: 0.5,
  bodyFontColor: ThemeColors().primaryColor,
  bodySpacing: 10,
  xPadding: 15,
  yPadding: 15,
  cornerRadius: 0.15
}

export const centerTextPlugin = {
  afterDatasetsUpdate: function (chart) {
  },
  beforeDraw: function (chart) {
    var width = chart.chartArea.right
    var height = chart.chartArea.bottom
    var ctx = chart.chart.ctx
    ctx.restore()

    var activeLabel = chart.data.labels[0]
    var activeValue = chart.data.datasets[0].data[0]
    var dataset = chart.data.datasets[0]
    var meta = dataset._meta[Object.keys(dataset._meta)[0]]
    var total = meta.total

    var activePercentage;
    try {
      activePercentage = parseFloat(((activeValue / total) * 100).toFixed(1))
      activePercentage = chart.legend.legendItems[0].hidden
        ? 0
        : activePercentage
    } catch (e) {
      activePercentage = 0
    }
    if (chart.pointAvailable) {
      activeLabel = chart.data.labels[chart.pointIndex]
      activeValue =
        chart.data.datasets[chart.pointDataIndex].data[chart.pointIndex]

      dataset = chart.data.datasets[chart.pointDataIndex]
      meta = dataset._meta[Object.keys(dataset._meta)[0]]
      total = meta.total
      activePercentage = parseFloat(((activeValue / total) * 100).toFixed(1))
      try {
        activePercentage = chart.legend.legendItems[chart.pointIndex].hidden
          ? 0
          : activePercentage
      } catch (e) {
        activePercentage = 0
      }
    }


    ctx.font = width > 220 ? '1.875rem Nunito, sans-serif' : width > 180 ? '1.4rem Nunito, sans-serif' : '0.750rem Nunito, sans-serif'
    ctx.fillStyle = ThemeColors().primaryColor
    ctx.textBaseline = 'middle'

    var text = activePercentage + '%'
    var textX = Math.round((width - ctx.measureText(text).width) / 2)
    var textY = height / 2 + 10
    ctx.fillText(text, textX, textY)

    ctx.font = width > 220 ? '0.975rem Nunito, sans-serif' : '0.6rem Nunito, sans-serif'
    ctx.textBaseline = 'middle'

    var text2 = activeLabel
    var textX2 = Math.round((width - ctx.measureText(text2).width) / 2)
    var textY2 = height / 2 - 15
    ctx.fillText(text2, textX2, textY2)

    ctx.save()
  },
  beforeEvent: function (chart, event, options) {
    var firstPoint = chart.getElementAtEvent(event)[0]

    if (firstPoint) {
      chart.pointIndex = firstPoint._index
      chart.pointDataIndex = firstPoint._datasetIndex
      chart.pointAvailable = true
    }
  }
}

export const getDirection = () => {
  let direction = 'ltr';
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction')
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  }
}

export const setDirection = localValue => {
  let direction = 'ltr'
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue
  }
  localStorage.setItem('direction', direction)
}

export const oneHundreePorcentPlugin = {
  afterDatasetsUpdate: function (chart) {
  },
  beforeDraw: function (chart) {
    var width = chart.chartArea.right
    var height = chart.chartArea.bottom
    var ctx = chart.chart.ctx
    ctx.restore()

    var creditLabel = chart.data.labels[0]
    var debitLabel = chart.data.labels[1]

    var dataset = chart.data.datasets[0]
    var meta = dataset._meta[Object.keys(dataset._meta)[0]]
    var total = meta.total

    var creditPercentage,debitPercentage, pixPercentage, ticketPercentage, mainText ;
    try {
      creditPercentage = parseFloat(((chart.data.datasets[0].data[0] / total) * 100).toFixed(1))
      creditPercentage = chart.legend.legendItems[0].hidden || total === 0
        ? 0
        : creditPercentage

      debitPercentage = parseFloat(((chart.data.datasets[0].data[1] / total) * 100).toFixed(1))
      debitPercentage = chart.legend.legendItems[1].hidden || total === 0
        ? 0
        : debitPercentage

      mainText = creditPercentage + debitPercentage  === 0
        ? "0 %"
        : 100 + '%'
    } catch (e) {
      creditPercentage = 0
      debitPercentage = 0
    }

    ctx.font = width > 220 ? '1.875rem Nunito, sans-serif' : width > 180 ? '1.4rem Nunito, sans-serif' : '0.750rem Nunito, sans-serif'
    ctx.fillStyle = ThemeColors().primaryColor
    ctx.textBaseline = 'middle'

    var text = mainText
    var textX = Math.round(((width + 55) - ctx.measureText(text).width) / 2)
    var textY = height / 2
    var text1 = creditPercentage + ' %'
    var textX1 = 0
    var textY1 = height / 3
    var text2 = Number(debitPercentage) + ' %'
    var textX2 = 0
    var textY2 = height >= 176 ? height - 50 : height >= 144 ? height - 30 : height - 10
    ctx.fillText(text, textX, textY)
    ctx.font = width >= 188 ? '0.813rem Nunito, sans-serif' : '0.700rem Nunito, sans-serif'
    ctx.fillText(text1, textX1, textY1)
    ctx.fillText(text2, textX2, textY2)
    ctx.textBaseline = 'right'
    ctx.font = width >= 188 ? `0.813rem Nunito, sans-serif` : `0.688rem Nunito, sans-serif`
    ctx.textBaseline = 'middle'

    var textLabel1 = creditLabel
    var textXLabel1 = 0
    var textYLabel1 = height / 3 - 30
    var textLabel2 = debitLabel
    var textXLabel2 = 0
    var textYLabel2 = height >= 176 ? height - 80 : height >= 144 ? height - 60 : height - 40
    ctx.fillText(textLabel1, textXLabel1, textYLabel1)
    ctx.fillText(textLabel2, textXLabel2, textYLabel2)

    ctx.save()
  },
}

export const oneTypePlugin = {
  afterDatasetsUpdate: function (chart) {
  },
  beforeDraw: function (chart) {
    var width = chart.chartArea.right
    var height = chart.chartArea.bottom
    var ctx = chart.chart.ctx
    var i = 0
    ctx.restore()

    var activeLabel = chart.data.labels[0]
    var activeValue = chart.data.datasets[0].data[0]
    var dataset = chart.data.datasets[0]
    var meta = dataset._meta[Object.keys(dataset._meta)[0]]
    var total = meta.total

    if (chart.data.datasets[0].data[0] == 0) {
      while(chart.data.datasets[0].data[i] == 0 ) {
        i++
      }
      activeLabel = chart.data.labels[i]
      activeValue = chart.data.datasets[0].data[i]
      dataset = chart.data.datasets[0]
    }

    var activePercentage;
    try {
      if (chart.legend.legendItems[i].hidden && total != 0) {
        while(chart.legend.legendItems[i].hidden) {
          i++
        }
        activeLabel = chart.data.labels[i]
        activeValue = chart.data.datasets[0].data[i]
        dataset = chart.data.datasets[0]
        activePercentage = parseFloat(((activeValue / total) * 100).toFixed(1))
      } else if (total == 0) {
        activePercentage = 0
        activeLabel = chart.data.labels[i]
        dataset = chart.data.datasets[0]
      } else {
        activePercentage = parseFloat(((activeValue / total) * 100).toFixed(1))

      }
    } catch (e) {
      activePercentage = 0
    }

    if (chart.pointAvailable) {
      activeLabel = chart.data.labels[chart.pointIndex]
      activeValue =
        chart.data.datasets[chart.pointDataIndex].data[chart.pointIndex]

      dataset = chart.data.datasets[chart.pointDataIndex]
      meta = dataset._meta[Object.keys(dataset._meta)[0]]
      total = meta.total
      activePercentage = parseFloat(((activeValue / total) * 100).toFixed(1))
      try {
        activePercentage = chart.legend.legendItems[chart.pointIndex].hidden
          ? 0
          : activePercentage
      } catch (e) {
        activePercentage = 0
      }
    }


    ctx.font = '36px Nunito, sans-serif'
    ctx.fillStyle = ThemeColors().primaryColor
    ctx.textBaseline = 'middle'

    var text = activePercentage + '%'
    var textX = Math.round((width - ctx.measureText(text).width) / 2)
    var textY = height / 2
    ctx.fillText(text, textX, textY)

    ctx.font = '14px Nunito, sans-serif'
    ctx.textBaseline = 'middle'

    var text2 = activeLabel
    var textX2 = Math.round((width - ctx.measureText(text2).width) / 2)
    var textY2 = height / 2 - 30
    ctx.fillText(text2, textX2, textY2)

    ctx.save()
  },
  beforeEvent: function (chart, event, options) {
    var firstPoint = chart.getElementAtEvent(event)[0]

    if (firstPoint) {
      chart.pointIndex = firstPoint._index
      chart.pointDataIndex = firstPoint._datasetIndex
      chart.pointAvailable = true
    }
  }
}

export const formatPrice = (value) => {
  let val = (value / 1).toFixed(2).replace(".", ",");
  return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
}

export const formatPriceToBr = (value) => {
  let val = (value / 1).toFixed(2).replace(".", ",");
  return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
}
export const removeFormatPrice = (value) => {
  if (typeof (value) != "undefined ") {
    let valueFormatted = parseFloat(value.toString().replaceAll("R$ ", "").replace(".", "").replace(",", "."))
    return isNaN(valueFormatted) ? 0 : valueFormatted
  } else {
    return 0.0
  }
}

export const conectSQS = () => {
  let AWS = require('aws-sdk');
  AWS.config.update({
    region: 'us-east-1',
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey
  });
  let sqs = new AWS.SQS({
    apiVersion: '2012-11-05'
  });
  return sqs;
}

export const b64EncodeUnicode = (str) => {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
}

export const b64DecodeUnicode = (str) => {
  try {
    return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  } catch (e) {
    console.log(e);
    return str;
  }
}

export const formatDate = (date) => {
  return new Date(date).toLocaleString('pt-BR', {timeZone: 'UTC'})
}

export const formatCpfCnpj = (cpf_cnpj) => {
  let cpfCnpj = cpf_cnpj.replace(/\D/g, '');

  if (cpfCnpj.length === 11) {
    return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else if (cpfCnpj.length === 14) {
    return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  } else {
    return cpfCnpj;
  }
}

export const convertBaseInJSON = (data) => {
  try {
    return JSON.parse(data)
  } catch {
    return {}
  }
}

export const isDateValid = (dateString) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

export const priceFormatter = (decimals) => new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: decimals,
})

export const formatDecimals = (value, decimals) => {
  let valor = value
  let val
  if (decimals === 2) {
    val = Number(valor).toLocaleString("pt-BR", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  } else if (decimals === 4) {
    val = Number(valor).toLocaleString("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
  }
  return val
}

export const returnDateOrIfen = (date) => {
  if (date === null || date === undefined) return ' - '
  if (date?.toString().indexOf('0001-01-01') !== -1) return ' - '

  return date
}

export const maxStartDate = () => {
  // Calculate the first day of the previous year
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const firstDayOfPreviousYear = new Date(currentYear - 1, 0, 1);
  return firstDayOfPreviousYear;
}

export const maxEndDate = (startDateSelected) => {
  if (startDateSelected) {
    const startDate = new Date(startDateSelected);
    const maxEndDate = new Date(startDate.getTime() + 31 * 24 * 60 * 60 * 1000); // Add 31 days
    const today = new Date();

    const endDate = maxEndDate > today ? today : maxEndDate;

    return endDate.getTime() - startDate.getTime() > 31 * 24 * 60 * 60 * 1000
      ? new Date(startDate.getTime() + 31 * 24 * 60 * 60 * 1000)
      : endDate;
  }
  return "";
};
